import { AxiosInstancePlus } from '@tencent/oneid-request';
import type { IAccount } from 'common/constants/Types';
import { http } from 'common/http';

class AccountApi {
  public axiosInstance: AxiosInstancePlus = http;

  /**
   * 获取企业信息
   */
  getAccountInfo(): Promise<IAccount> {
    return this.axiosInstance.get('/admin/v3/account');
  }
}
export default new AccountApi();
