import BaseApi from 'common/apiRequest/apis/BaseApi';
import JSRuntime from 'common/utils/JSRuntime';
import { Request } from '../apiConcentrator/User';
import APINames from '../APINames';

class VendorApi extends BaseApi {
  public requestInstance = new Request(APINames.VENDOR, {
    baseURL: `${JSRuntime.oauth2Site}/v1`,
  });

  // 根据 app_type 获取服务商品牌展示
  getExternalAppInfoByType(appType: string) {
    return this.requestInstance.get(['apps'], { app_type: appType });
  }

  // 获得 authorize 接口
  getAuthorizeUrl(qs: string) {
    const baseURL = _.get(this.requestInstance, 'baseURL');
    return `${baseURL}/authorize?${qs}`;
  }
}
export default new VendorApi();
