import qs from 'qs';
import isURL from 'validator/lib/isURL';
import { AuthnApi as CommonAuthnApi } from 'common/api';
import Utils from 'common/utils';
import { handleError } from 'common/utils/ErrorHandler';
import JSRuntime from 'common/utils/JSRuntime';

const identityRedirect = (
  baseRedirectUrl: string,
  stateId?: string,
  handleError?: (err: string) => void,
  defaultRedirectUrl?: string,
) => {
  if (!baseRedirectUrl || !_.isString(baseRedirectUrl)) {
    handleError?.('登录方式配置有误，请重新配置'); // 理论上不会到这里
    return;
  }

  const query = JSRuntime.parseLocationSearch(_.get(window.location, 'search'));
  const errorQs = query.origin ? `?${qs.stringify({ origin: query.origin })}` : '';

  const config = {
    origin: undefined,
    stateId,
    // NOTICE 给单个直接跳转的认证源使用
    cidp: window.encodeURIComponent(JSRuntime.getIdpSite(undefined, '/cidp')),
    // NOTICE 给单个直接跳转的认证源报错使用
    errorCidp: window.encodeURIComponent(JSRuntime.getIdpSite(errorQs, '/cidp/error')),
    redirectUrl: query.redirectUrl
      ? JSRuntime.getOAuth2RedirectUrl(query.redirectUrl, 'username')
      : defaultRedirectUrl,
  };
  const params = qs.stringify({
    forceAuthn: true,
    state: window.btoa(JSON.stringify(stateId ? config : _.omit(config, 'stateId'))),
  });
  const redirectUrl = `${baseRedirectUrl.replace(/\/$/, '')}?${params}`;
  if (isURL(redirectUrl)) {
    Utils.safeRedirect(redirectUrl);
  } else {
    handleError?.('登录方式配置有误，请稍后重试');
  }
};

const TOF_AUTH = 'TOF_AUTH'; //  iOA认证,tx租户专用

export const redirectIOA: (
  res: { next: Record<string, any> },
  defaultRedirectUrl?: string,
) => boolean = (res, defaultRedirectUrl) => {
  const tofUri = m.get(res, 'next.tofUri') || '';
  const type = m.get(res, 'next.type') || '';
  if (!tofUri || !_.isString(tofUri) || type !== TOF_AUTH) return false;
  const configUrl = new URL(tofUri);
  const pathName = configUrl.pathname.split('/').filter((path) => path);
  const identityId = _.find(pathName, () => true, -1) || '';
  const accountId = _.find(pathName, () => true, -3) || '';
  CommonAuthnApi.getSSOLoginState(accountId, identityId)
    .then(({ state }) => {
      identityRedirect(tofUri, state, handleError, defaultRedirectUrl);
    })
    .catch(handleError);
  return true;
};
