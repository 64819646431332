import { observer } from 'mobx-react';
import type { FC } from 'react';
import { useEffect } from 'react';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi } from 'idpBase/http';
import { useStore } from 'idpBase/store';

/** 需要单独打通登录态的页面 */
const LOGIN_WITH_CODE_WHITE_LIST = ['#/auth', '#/auth-enterprise'];

/**
 * 依赖登录态的页面都需要用这个包裹
 * @param PageComponent 页面组件
 */
const withLogin = (PageComponent: FC) => {
  return observer((props: any) => {
    const { rootStore } = useStore();

    const initGlobalData = async () => {
      const hash = Utils.getPureHashPath();
      const searchObj = JSRuntime.parseLocationSearch();
      // 个人认证、企业认证页面在会议app里使用，需要根据页面参数单独打通登录态
      if (searchObj.code && searchObj.client && LOGIN_WITH_CODE_WHITE_LIST.includes(hash)) {
        await AuthnApi.getAuthCodeSession({
          code: searchObj.code,
          client: searchObj.client,
        });
      }

      const userInfo = await rootStore.getUserInfo();
      if (!userInfo) return;

      const account = await rootStore.getAccountInfo();

      rootStore.getJoinRoles();
      rootStore.getSettings({ accountId: account.accountId });
    };

    useEffect(() => {
      initGlobalData();
    }, []);

    // 用户信息 + 企业信息获取成功后再渲染页面
    if (
      _.isEmpty(mobx.toJS(rootStore.userInfo.accountUser)) ||
      _.isEmpty(mobx.toJS(rootStore.account))
    )
      return null;

    return <PageComponent {...props} />;
  });
};

export default withLogin;
