import { AxiosInstancePlus } from '@tencent/oneid-request';
import type { IResponseType, IUserInfo } from 'common/constants/Types';
import { http } from 'common/http';

class AuthnApi {
  public axiosInstance: AxiosInstancePlus = http;

  /**
   * 获取用户信息
   * @returns IUserInfo
   */
  getSelfV3(params?: { app?: string }): Promise<Required<IResponseType<IUserInfo>>> {
    return this.axiosInstance.get('/v1/authn:get_self_v3', { params, skipShowError: true });
  }
}
export default new AuthnApi();
