import { AxiosRequestConfig, createInstance } from '@tencent/oneid-request';
import { onError, onSuccess } from './Interceptors';

const createAxiosInstance = (config: AxiosRequestConfig) => {
  // 创建实例
  const axiosInstance = createInstance(config);

  // 添加通用拦截器
  axiosInstance.interceptors.response.use(
    (response) => {
      return onSuccess(response);
    },
    (error) => {
      return onError(error);
    },
  );

  return axiosInstance;
};

export default createAxiosInstance;
