/**
 * 运行时数据
 * 先于 Utils, 所以规范上不要在这个文件中引用 Utils
 * */
import _ from 'lodash';
import qs from 'qs';
import validator from 'validator';

class JSRuntime {
  public isLocalDevelopment = RUNTIME_NODE_ENV === 'development';
  public nodeEnv = RUNTIME_NODE_ENV;
  public staticDir = RUNTIME_STATIC_DIR;
  // 以下变量经过处理最后一位不会是 / 且 SITE 类的都是 有协议开头的 https://
  public cdnUrl = _.trimEnd(window.ONEID_PUBLIC_CDN_URL, '/');
  public apiSite = _.trimEnd(window.ONEID_PUBLIC_GATEWAY_URL, '/');
  public accountApiSite = _.trimEnd(window.ONEID_ACCOUNT_GATEWAY_URL, '/');
  public idpSite = _.trimEnd(window.ONEID_PUBLIC_IDP_SITE, '/');
  public portalSite = _.trimEnd(window.ONEID_PUBLIC_PORTAL_SITE, '/');
  public adminSite = _.trimEnd(window.ONEID_PUBLIC_ADMIN_SITE, '/');
  public officialSite = _.trimEnd(window.ONEID_PUBLIC_OFFICIAL_SITE, '/');
  public originalIdpSite = _.trimEnd(window.ONEID_PUBLIC_ORIGINAL_IDP_SITE, '/');
  public oauth2Site = _.trimEnd(window.ONEID_PUBLIC_OAUTH2_URL, '/');
  public accountOauth2Site = _.trimEnd(window.ONEID_ACCOUNT_OAUTH2_URL, '/');
  public dingTalkIdentitySite = _.trimEnd(window.ONEID_DINGTALK_IDENTITY_SITE, '/');
  public meetingSite = _.trimEnd(window.ONEID_MEETING_SITE, '/');
  public isCustomDomain =
    !!window.ONEID_PUBLIC_CUSTOM_DOMAIN || !!window.ONEID_PUBLIC_ACCOUNT_DOMAIN; // 是否为自定义域名（目前自定义域名包含企业级域名 & 私有化域名）
  public isPrivate = RUNTIME_LOCAL_ENV === 'private';
  /** 是否由企业标识组合成的SaaS域名 */
  public isAccountDomain = !!window.ONEID_PUBLIC_ACCOUNT_DOMAIN;
  /** 页面地址是否需要有企业标识 */
  public needAccountDomain = false;
  public isFullCustomDomain = !!window.ONEID_PUBLIC_CUSTOM_DOMAIN; // 完全自定义域名（包括SaaS和私有化）
  public isProductSite = RUNTIME_LOCAL_ENV === 'prod' || RUNTIME_LOCAL_ENV === 'pre';
  public tokenFromMessage = '';
  // value作为后端传的app key作为前端app
  public front2BackApp = {
    meeting: 'meeting',
    docs: 'doc',
    gdes: '2023gdes', // 生态大会官网
    sogouinput: 'sogouinput',
  };

  public suiteAppNameMap = {
    MEETING: '腾讯会议',
    DOCS: '腾讯文档',
    SOGOUINPUT: '腾讯搜狗输入法',
  };

  get isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile/i.test(
      navigator.userAgent,
    );
  }

  // 根据ua + 宽度判断是否为移动端
  get isMobileUI() {
    return this.isMobileDevice || document?.body?.clientWidth <= 600;
  }

  get isWechat() {
    // 不是企业微信
    return /micromessenger/i.test(navigator.userAgent) && !/wxwork/i.test(navigator.userAgent);
  }

  // 微信小程序
  get isWechatMiniProgram() {
    return this.isWechat && /miniProgram/i.test(navigator.userAgent);
  }

  // 企业微信浏览器
  get isWxworkBrowser() {
    return /wxwork/i.test(navigator.userAgent);
  }

  get appFromUrl(): string {
    const searchParams = this.parseLocationSearch();
    return _.get(searchParams, 'app');
  }

  get isTencentMeeting(): boolean {
    return this.appFromUrl === this.front2BackApp.meeting;
  }

  get isTencentDocs(): boolean {
    return this.appFromUrl === this.front2BackApp.docs;
  }

  public get isSogouInput(): boolean {
    return this.appFromUrl === this.front2BackApp.sogouinput;
  }

  // 生态大会官网
  get isGDES(): boolean {
    return this.appFromUrl === this.front2BackApp.gdes;
  }

  get backEndApp(): string | undefined {
    const app = this.appFromUrl;
    return _.values(this.front2BackApp).includes(app) ? app : undefined;
  }

  get suiteAppName(): string {
    if (this.isTencentMeeting) {
      return this.suiteAppNameMap.MEETING;
    }
    if (this.isTencentDocs) {
      return this.suiteAppNameMap.DOCS;
    }
    if (this.isSogouInput) {
      return this.suiteAppNameMap.SOGOUINPUT;
    }
    return '';
  }

  // idp: 登录成功后的落地页
  // admin/common: 401/403后指定跳转页面
  get thirdLandingPage(): string {
    const searchParams = this.parseLocationSearch();
    const redirectUrl = _.get(searchParams, 'redirectUrl');
    if (!_.isString(redirectUrl)) return '';
    const isUrl = validator.isURL(redirectUrl, {
      require_protocol: true,
      require_tld: false,
    });
    return isUrl ? redirectUrl : '';
  }

  /**
   * 一个完整 iframe 请求类似 https://toa.toatest.yufuid.net?origin=iframe/#/users?userId=xxx
   * 其中 /#/ 前的 query 可以通过 window.location.search 获得
   * /#/ 后的 query 通过 Route 提供的 location.search 获得
   * */
  get isIframeOrigin(): boolean {
    const searchParams = this.parseLocationSearch();

    return _.get(searchParams, 'origin') === 'iframe';
  }

  // admin中自定义样式编辑部分的演示区域
  get isBrandDemoOrigin(): boolean {
    const searchParams = this.parseLocationSearch();

    return _.get(searchParams, 'origin') === 'brandDemo';
  }

  /**
   * 企业级登录自定义样式-演示登录
   * 已有登录态，不跳转到账号选择，可以输入账号密码走正常登录流程
   * */
  get isLoginDemoOrigin(): boolean {
    const searchParams = this.parseLocationSearch();

    return _.get(searchParams, 'origin') === 'loginDemo';
  }

  get urlToken(): string {
    const searchParams = this.parseLocationSearch();

    return _.get(searchParams, 'token') || '';
  }

  get agreementUrl() {
    return {
      service: this.getAgreementSite('agreement'),
      privacy: this.getAgreementSite('privacypolicy'),
      verify: this.getAgreementSite('verifyagreement'),
      suspended: this.getAgreementSite('suspended'),
    };
  }

  setTokenFromMessage(token: string) {
    if (_.isString(token)) this.tokenFromMessage = token;
    if (this.isIframeOrigin) {
      this.iframePostMessage({
        type: 'toaTokenReceived',
        payload: { token },
      });
    }
  }

  analysisBrowser(): { isIE: boolean; isFirefox: boolean; isSafari: boolean } {
    const userAgent = _.get(navigator, 'userAgent') || '';
    return {
      isIE: !!window.ActiveXObject || 'ActiveXObject' in window,
      isFirefox: userAgent.indexOf('Firefox') > -1,
      isSafari: userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') < 0,
    };
  }

  printMsg(msg: Error | string, pattern?: 'error' | 'warn'): void {
    const currentPattern = pattern || 'warn';
    if (this.isLocalDevelopment && currentPattern === 'error') {
      if (typeof msg === 'string') {
        throw new Error(msg);
      } else {
        throw msg;
      }
    }
  }

  warn(msg: string): void {
    this.printMsg(msg, 'warn');
  }

  error(error: Error | string): void {
    this.printMsg(error, 'error');
  }

  private joinSite(baseUrl: string, qsHash: string, path?: string) {
    return [baseUrl.replace(/\/$/, ''), qsHash, path ? path.replace(/^\//, '') : ''].join('');
  }

  // 经过自定义域名处理过的 idp 地址
  getIdpSite(qs?: string, path?: string): string {
    return this.joinSite(this.idpSite, `/${qs || ''}#/`, path);
  }

  getDingTalkIdentitySite(qs?: string, path?: string) {
    return this.joinSite(this.dingTalkIdentitySite, `/${qs || ''}#/`, path);
  }

  // SaaS 版的 idp 地址
  getOriginalIdpSite(qs?: string, path?: string): string {
    return this.joinSite(this.originalIdpSite, `/${qs || ''}#/`, path);
  }

  getPortalSite(qs?: string, path?: string): string {
    return this.joinSite(this.portalSite, `/portal/${qs || ''}#/`, path);
  }

  getAdminSite(qs?: string, path?: string): string {
    return this.joinSite(this.adminSite, `/admin/${qs || ''}#/`, path);
  }

  getDocsSite(path?: string): string {
    if (window.ONEID_PUBLIC_DOCS_SITE) {
      return this.joinSite(_.trimEnd(window.ONEID_PUBLIC_DOCS_SITE, '/'), `/`, path);
    }
    return this.joinSite(this.officialSite, `/docs/`, path);
  }

  getAgreementSite(path?: string): string {
    if (this.isPrivate) return this.getDocsSite(path);
    return this.joinSite(this.officialSite, `/`, path);
  }

  getOAuth2RedirectUrl(originUrl: string, username: string, isAuthUrl?: boolean): string {
    if (!originUrl) {
      return '';
    }
    const decodeUrl = decodeURI(originUrl);
    const newUrl = new URL(decodeUrl);
    const isOauth2Url = newUrl?.origin.includes(this.oauth2Site);
    if (isAuthUrl && !isOauth2Url) {
      return `${this.accountOauth2Site}/v1/auth?goto=${originUrl}`;
    }

    if (!isOauth2Url) {
      return originUrl;
    }
    const params = this.parseLocationSearch(newUrl?.search || '', { transferNum: false });
    return `${newUrl?.origin}${newUrl?.pathname}?${qs.stringify({
      ...params,
      login_user: username,
    })}`;
  }

  getStaticPath(path: string) {
    const { protocol, host, pathname } = window.location;
    const currentSite = `${protocol}//${host}${pathname}`;
    return [_.trimEnd(currentSite, '/'), _.trim(this.staticDir, '/'), _.trim(path, '/')].join('/');
  }

  public parseLocationSearch = (
    search: string = _.get(window.location, 'search'),
    options: { transferNum: boolean } = { transferNum: true },
  ): { [k: string]: any } => {
    const currentSearch = search.replace(/^\?/g, '').replace(/\/$/g, '');
    if (!currentSearch) return {};
    const obj = qs.parse(currentSearch) || {};
    const parseValue = (value: any): any => {
      // 类似?iframe
      if (value === '') return true;
      if (_.get(options, 'transferNum') && !_.isNaN(_.toNumber(value))) return _.toNumber(value);
      if (value === 'true') return true;
      if (value === 'false') return false;
      return value;
    };
    _.forIn(obj, (value: any, key: string) => {
      obj[key] = parseValue(value);
    });
    return obj;
  };

  public iframePostMessage(message: any, targetOrigin = '*') {
    if (!_.isFunction(_.get(window, 'parent.postMessage'))) {
      return;
    }
    window.parent.postMessage(message, targetOrigin);
  }

  public popupPostMessage(message: any, targetOrigin = '*') {
    if (!_.isFunction(_.get(window, 'opener.postMessage'))) {
      return;
    }
    window.opener.postMessage(message, targetOrigin);
  }

  public getSubscribeUrl = (url?: string) => {
    // NOTICE 去开通，需要在redirectUrl的redirect_uri参数上拼接prompt=skip_select_account
    if (!_.isString(url)) return;
    const redirectUrl = new URL(url);
    const secondaryQuery = this.parseLocationSearch(redirectUrl.search);
    const newSecondaryQuery = { ...secondaryQuery, prompt: 'skip_select_account' };
    const newRedirectUrl = `${redirectUrl.origin}${redirectUrl.pathname}?${qs.stringify(
      newSecondaryQuery,
    )}`;
    return newRedirectUrl;
  };
}

export default new JSRuntime();
