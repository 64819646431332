import { LoadingIcon } from 'tdesign-icons-react';
import Utils from 'common/utils';
import ButtonGroup from './ButtonGroup';
import { ButtonColor, ButtonMode, ButtonShape, ButtonSize } from './types';
import './style/Button.scss';

type ButtonModeType = `${ButtonMode}`;
type ButtonColorType = `${ButtonColor}`;
type ButtonSizeType = `${ButtonSize}`;
type ButtonShapeType = `${ButtonShape}`;

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: React.ReactNode;
  /**
   * 按钮形态 - 实心按钮、线框按钮、无底色按钮
   * @default contained
   */
  mode?: ButtonModeType;
  /**
   * 按钮颜色 - 主色、红色
   * @default primary
   */
  color?: ButtonColorType;
  /**
   * 按钮大小
   * @default default
   */
  size?: ButtonSizeType;
  /**
   * 按钮是否不可用
   * @default false
   */
  disabled?: boolean;
  /**
   * 按钮是否正在加载...
   * @default false
   */
  loading?: boolean;
  /**
   * 按钮是否与父元素同宽
   * @default false
   */
  fullWidth?: boolean;
  /**
   * 按钮点击事件
   * @default () => {}
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * 按钮形状，为了兼容正方形按钮
   * @default default
   */
  shape?: ButtonShapeType;
  /**
   * fullWidth为true且按钮文字为两个字时，增加文字letter-space
   */
  hasSpaceLetter?: boolean;
}

export const classNamePrefix = 'eid-btn';

export const classes = {
  base: classNamePrefix,
  appearance: (mode: ButtonProps['mode'], color: ButtonProps['color']): string =>
    `${classNamePrefix}-${mode}-${color}`,
  size: (size: ButtonProps['size']): string => `${classNamePrefix}-${size}`,
  shape: (shape: ButtonProps['shape']): string => `${classNamePrefix}-shape-${shape}`,
  loading: `${classNamePrefix}-loading`,
  disabled: `${classNamePrefix}-disabled`,
  full: `${classNamePrefix}-full`,
  autoFocus: `${classNamePrefix}-autoFocus`,
  letterSpace: `${classNamePrefix}-with-letter-space`,
};

const LOADING_SIZE = {
  [ButtonSize.DEFAULT]: 18,
  [ButtonSize.LARGE]: 20,
  [ButtonSize.SMALL]: 14,
};

const Button = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children = null,
      loading = false,
      mode = 'contained',
      color = 'primary',
      size = 'large',
      disabled = false,
      fullWidth = false,
      hasSpaceLetter = true,
      className = '',
      autoFocus,
      shape = 'default',
      onMouseUp,
    } = props;
    let buttonNode: HTMLButtonElement | null = null;

    const [isUnFocused, setUnFocused] = React.useState(false);

    const buttonRef = React.useCallback(
      (button) => {
        buttonNode = button;
        if (_.isFunction(ref)) {
          ref(button);
        } else if (_.isObject(ref)) {
          ref.current = button;
        }
      },
      [ref],
    );

    React.useEffect(() => {
      if (autoFocus) {
        const timer = setTimeout(() => {
          buttonNode?.focus();
          clearTimeout(timer);
        }, 0);
      }
    }, [autoFocus, buttonNode]);

    // TODO: loading icon 待定
    const LoadingSvg = mode === ButtonMode.CONTAINED ? LoadingIcon : LoadingIcon;

    const otherProps = _.omit(props, [
      'className',
      'children',
      'mode',
      'color',
      'size',
      'disabled',
      'loading',
      'fullWidth',
      'onMouseUp',
      'hasSpaceLetter',
    ]);

    const onButtonMouseUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (_.isFunction(onMouseUp)) onMouseUp(e);
      setUnFocused(true);
    };

    return (
      <button
        className={Utils.uniteClass(
          classes.base,
          classes.appearance(mode, color),
          classes.size(size),
          classes.shape(shape),
          loading ? classes.loading : '',
          disabled ? classes.disabled : '',
          fullWidth ? classes.full : '',
          autoFocus && !isUnFocused ? classes.autoFocus : '',
          fullWidth && hasSpaceLetter && _.isString(children) && children.length === 2
            ? classes.letterSpace
            : null,
          className,
        )}
        ref={buttonRef}
        type="button"
        disabled={disabled || loading}
        {...otherProps}
        onMouseUp={onButtonMouseUp}
      >
        {loading ? (
          <LoadingSvg
            className={`${classNamePrefix}-loading-svg`}
            size={LOADING_SIZE[size as ButtonSize]}
          />
        ) : (
          children
        )}
      </button>
    );
  },
) as React.ForwardRefExoticComponent<ButtonProps & React.RefAttributes<HTMLButtonElement>> & {
  Group: typeof ButtonGroup;
};

export default Button;
