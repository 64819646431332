import BaseApi from 'common/apiRequest/apis/BaseApi';
import { IResetPasswordUrlParams } from 'common/constants/Types';
import captchaRequest from 'common/utils/captchaRequest';
import { Request } from '../apiConcentrator/User';
import APINames from '../APINames';

class AuthnApi extends BaseApi {
  public requestInstance = new Request(APINames.USER);

  // 注册码获取
  getRegisterCode(mobile: string) {
    return captchaRequest((captchaVerification) =>
      this.requestInstance.post(['signup', 'sms:send'], {
        mobile,
        captchaVerification,
      }),
    );
  }

  // 注册码验证 + 账号注册
  register(params: { verificationSessionId: string; verificationCode: string; app?: string }) {
    return captchaRequest((captchaVerification) =>
      this.requestInstance.post(['signup'], {
        ...params,
        captchaVerification,
      }),
    );
  }

  // 获取重置密码链接的状态、用户名、密码策略等信息
  getResetPasswordInitialData(params: IResetPasswordUrlParams) {
    return this.requestInstance.post(['enterprise', 'password_reset_url_check'], params);
  }

  resetPwdByUrl(params: IResetPasswordUrlParams & { credential: string }) {
    return this.requestInstance.post(['enterprise', 'password:url_reset'], params);
  }
}
export default new AuthnApi();
