import {
  CAPTCHA_TYPE,
  loadCaptcha,
  resolvePromise,
  showCaptcha,
  TCaptchaConfigs,
  TCaptchaVerification,
} from '@tencent/oneid-utils';
import message from 'common/components/Message';
import { CAPTCHA_ERROR } from 'common/constants/Constants';
import Utils from 'common/utils/index';
import JSRuntime from 'common/utils/JSRuntime';

/** 预加载滑块，用于大概率会触发滑块的页面 */
export const preloadCaptcha = () => {
  // TODO: 后端现网先使用了天御，待TEG上线后，删除JSRuntime.isProductSite
  loadCaptcha(JSRuntime.isProductSite ? CAPTCHA_TYPE.TENCENT : CAPTCHA_TYPE.TEG).catch(Utils.noop);
};

const captchaRequest = async (
  request: (captchaVerification?: TCaptchaVerification) => Promise<any>,
  configs?: TCaptchaConfigs,
) => {
  const requestApi = request();
  const [err, res] = await resolvePromise(requestApi);
  if (err || !res || res.errCode) return requestApi;

  const successType = res.next?.type;
  // 没触发滑块验证，直接返回数据
  if (successType !== 'CAPTCHA_OPTIONS') return requestApi;

  // 触发了滑块验证的场景
  try {
    const captchaOptions = res.next?.captchaOptions?.options;
    const { captchaVerification, errorLog } = await showCaptcha(captchaOptions, configs);

    // 如果期间存在报错，需要进行上报，方便问题统计与定位
    if (errorLog.length) {
      window.aegis?.report({
        name: CAPTCHA_ERROR,
        message: '用户成功加载验证码，但是期间出现过错误',
        count: errorLog.length,
        errorLog,
      });
    }
    // 用户成功通过验证码
    if (captchaVerification) {
      return request(captchaVerification);
    }
    return Promise.reject({ data: { errCode: CAPTCHA_ERROR, errMessage: '滑块验证码关闭' } });
  } catch (err) {
    const { errCode, errMessage, ...rest } = err.data || {};
    // 外侧的验证码错误类型无错误提示，需要在内部处理
    if (errCode === CAPTCHA_ERROR) {
      errMessage && message.error(errMessage);
      window.aegis?.error({
        name: errCode,
        message: errMessage,
        ...rest,
      });
    }
    // 外部需要对滑块错误进行业务层面处理
    return Promise.reject(err);
  }
};

export default captchaRequest;
