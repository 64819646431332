import { lazy, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import BlankPage from 'common/components/BlankPage';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { convert, useStore } from 'idpBase/store';
import withLogin from './hoc/withLogin';

const Login = lazy(() => import('../login/RouteEntry'));
const SocialLogin = lazy(() => import('../socialLogin/RouteEntry'));
const Register = lazy(() => import('../register'));
const BindTenant = lazy(() => import('../bindTenant'));
const SSO = lazy(() => import('../sso/RouteEntry'));
const ResetPassword = lazy(() => import('../resetPassword'));
const Invitation = lazy(() => import('../invitation'));
const CidpRouteEntry = lazy(() => import('../cidp/RouteEntry'));
const Logout = lazy(() => import('../logout'));

// 依赖登录态的的页面
const Profile = withLogin(lazy(() => import('../profile/RouteEntry')));
const Auth = withLogin(lazy(() => import('../auth/RouteEntry')));
const AuthEnterprise = withLogin(lazy(() => import('../authEnterprise/RouteEntry')));
const SelectTenant = withLogin(lazy(() => import('../selectAccount/selectTenant')));
const SelectAccount = withLogin(lazy(() => import('../selectAccount/selectAccount')));
const Consent = withLogin(lazy(() => import('../selectAccount/consent')));
const AppAuthorization = withLogin(lazy(() => import('../appAuthorization/RouteEntry')));

const IndexElement = convert(() => {
  const { rootStore } = useStore();
  const { userInfo } = rootStore;
  const { appType } = JSRuntime.parseLocationSearch(undefined, {
    transferNum: false,
  });

  // 有登录态 && 不是自定义域名
  if (!_.isEmpty(mobx.toJS(userInfo.accountUser)) && !JSRuntime.isCustomDomain) {
    if (appType) return <Navigate to="/app_authorization" />;

    rootStore.redirectToLandingPage();
    return null;
  }

  return <Navigate to={JSRuntime.isCustomDomain ? '/sso_login/identity' : '/login'} />;
});

const RouteEntry = () => {
  const isMustSsoLoginEnv = JSRuntime.isCustomDomain;

  useEffect(() => {
    const hash = Utils.getPureHashPath();
    if (hash === '#/app_authorization/authorize') return;
    const meta = document.createElement('meta');
    meta.httpEquiv = 'Content-Security-Policy';
    meta.content = 'form-action none';
    document.head.appendChild(meta);
  }, []);

  return useRoutes([
    {
      index: true,
      element: <IndexElement />,
    },
    // 自然人登录页
    {
      path: 'login/*',
      element: isMustSsoLoginEnv ? <Navigate to="/sso_login/identity" /> : <Login />,
    },
    // sso登录页
    { path: 'sso_login/*', element: <SSO /> },
    // QQ、微信扫码登录页
    {
      path: 'social_login/*',
      element: isMustSsoLoginEnv ? <Navigate to="/sso_login/identity" /> : <SocialLogin />,
    },
    // sso登录成功、失败之后的中转页，后端跳转过来的
    { path: 'cidp/*', element: <CidpRouteEntry /> },
    // 退出登录页面
    { path: 'logout', element: <Logout /> },
    // 开启注册功能或者会议来源，开放注册路由
    JSRuntime.isTencentMeeting || JSRuntime.isTencentDocs || JSRuntime.isSogouInput
      ? { path: 'register', element: <Register /> }
      : {},
    // 绑定租户页面
    { path: 'init_account', element: <BindTenant /> },
    // 第三方授权页
    { path: 'app_authorization/*', element: <AppAuthorization /> },
    // 个人中心
    { path: 'profile/*', element: <Profile /> },
    // 激活邀请 - 发送短信中携带的地址
    { path: 'invitation', element: <Invitation /> },
    // 重置密码 - 发送短信中携带的地址
    !JSRuntime.isPrivate ? { path: 'resetPwd/:token', element: <ResetPassword /> } : {},
    // 选择可以继续登录的账号
    { path: 'select_account', element: <SelectAccount /> },
    // 切换租户
    { path: 'select_tenant', element: <SelectTenant /> },
    // 当前登录账号+选择其他账号
    { path: 'consent', element: <Consent /> },
    // 个人认证
    { path: 'auth/*', element: <Auth /> },
    // 企业认证
    { path: 'auth-enterprise/*', element: <AuthEnterprise /> },
    { path: 'notFound', element: <BlankPage type="NOT_FOUND" /> },
    { path: '*', element: <Navigate to="/notFound" /> },
  ]);
};

export default RouteEntry;
