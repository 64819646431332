import I18N from 'common/utils/I18N';
import JSRuntime from 'common/utils/JSRuntime';
import publicStyles from 'common/styles/eid-variables-in-js.module.scss';

/** 企业用户不存在 */
export const NOT_FOUND_ERROR = 'E0010006';

/** 登录过期 || 未登录 */
export const NO_AUTH = 'E0010001';

/** 监控报警忽略的域名 */
export const AEGIS_IGNORE_DOMAINS = [
  'https://report.calendar.tencent.com/',
  'https://reporttest.calendar.tencent.com',
  'https://report.meeting.tencent.com',
  'https://report-test.meeting.tencent.com',
];

/** 监控报警忽略的错误消息 */
export const AEGIS_IGNORE_MESSAGES = [
  'Script error. @ (:0:0)',
  'Error.stack: undefined',
  'at <anonymous>',
  'Error.stack: global code',
  'chrome-extension://',
];

/** 正则匹配监控报警忽略的错误消息，注意性能问题，可能会堵塞页面渲染 */
export const AEGIS_IGNORE_REGEXPS = [/Error\.stack:\s*$/];

export enum SAVE_ORG_MSG_CODE {
  EXIST = 'E0010039',
  NOT_COMPLIANCE = 'E0010132', // 不合规
}

// 手动取消滑块或者滑块发生错误，不进行报错提示
export const CAPTCHA_ERROR = 'CAPTCHA_ERROR';

// 获取密码加密公钥接口错误
export const CREDENTIAL_ERROR = 'CREDENTIAL_ERROR';

// 手机号触发安全风险，禁止使用
export const MOBILE_SAFE_ERR_CODE = 'E0010334';

// 邮箱白名单用户在登录时拦截
export const EMAIL_WHITELIST_ERR = {
  code: 'E0010364',
  msg: '该企业不允许邮箱登录',
};

/** 统一错误码报错文案 */
export const ERROR_MSG = new Map<string | number, any>([
  [401, I18N.errorHandler.accessNotGranted],
  [403, I18N.errorHandler.noAccess],
  [404, I18N.errorHandler.inaccessible],
  [405, I18N.errorHandler.invalidOperation],
  [408, I18N.errorHandler.connectionTimedOut],
  [500, I18N.errorHandler.serverDifferent],
  [502, I18N.errorHandler.serverNo],
  [503, I18N.errorHandler.siteIs],
  [504, I18N.errorHandler.serverRoom],
  [0, I18N.errorHandler.networkNotConnected],
  ['E0000051', ''], // 搜索超过64字符
  ['E0010113', '当前链接已失效！'], // 应用开通绑定租户时code无效
  ['E0010217', '内容不合法，请修改后重新保存'], // 安全合规-内容风险 报错兜底（出现此文案则需进行排查）
  [MOBILE_SAFE_ERR_CODE, '检测到安全风险，操作已被拦截'],
  [CAPTCHA_ERROR, ''],
  [CREDENTIAL_ERROR, '服务器出现了问题，请再试一次'],
]);

export const NO_COMPANY_ERROR = 'NO_COMPANY_ERROR'; // 后端没有具体验证码，前端伪造一个用来展示错误
export const INVALID_CONTENT_ERROR_CODE = 'E0010217'; // 安全合规-内容风险 专用错误码

// 发送验证码达到上限，需要特殊处理的报错
export const VERIFY_CODE_LIMIT_ERR = new Map([
  ['E0010161', '今日验证码发送次数已达上限'],
  ['E0010229', '请求过于频繁，请稍后再试'],
]);

// 手机号是网状用户
export const NET_PHONE_ERROR_CODE = 'E0010332';

/** 全局enum */
export enum IDP_TYPE {
  WeCom = 'WeCom',
}

export enum WECOM_BIND_STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

// 企业用户名登录状态
export enum USERNAME_LOGIN_STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

/** 租户类型 */
export enum ACCOUNT_TYPE {
  /** 企业版 */
  COMPANY = 1,
  /** 个人版 */
  PERSONAL = 2,
}

/** 登录方式 */
export enum AUTHENTICATION_METHOD {
  /** 企业账号登录 */
  ENTERPRISE = 'Enterprise',
  /** 自然人账号登录 */
  PERSON = 'Person',
  /** 邮箱账号登录 */
  EMAIL = 'Email',
}

// 应用服务商类型
export enum VENDOR_TYPE {
  SSO = 'SSO', // 应用商城应用
  Tencent = 'Tencent', // 腾讯一方应用
  External = 'External', // 第三方应用/服务商应用
  Internal = 'Internal', // 企业自建应用
  KIT = 'KIT', // 腾讯套件应用
}

export const APP_STORE_SIGN_ON_MODE = {
  OIDC: 'OIDC',
};

/** 全局常量 */
export const UNKNOWN_MISTAKE = I18N.errorHandler.unknownMistake;

export enum APP_EDITION_TYPE {
  COMPANY = 1, // 企业版应用
  PERSONAL = 2, // 个人版应用
}

export const DEFAULT_COUNTDOWN_SECOND = 59;

export enum USER_VERIFY_CODE_SCENE {
  UPDATE_MOBILE = 'update_mobile',
  ASSIGN_USER = 'assign_user',
  ACCOUNT_CANCELLATION = 'account_cancellation',
  RESET_PASSWORD = 'reset_password',
  RESET_ENTERPRISE_PASSWORD = 'reset_enterprise_password',
  TENANT_CHANGE_BIND = 'tenant_change_bind', // 租户换绑
  ADMIN_AUTH = 'admin_auth',
  ASSIGN_USER_MFA = 'assign_user_mfa',
  VERIFY_ONLY = 'verify_only',
  /** 个人退出 */
  LOGOFF_USER = 'logoff_user',
  /** 解散企业 */
  ACCOUNT_DISMISS = 'account_dismiss',
}

export enum EMAIL_VERIFY_CODE_SCENE {
  EMAIL_SET = 'email_set', // 设置邮箱
  PASSWORD_SET = 'password_set', // 设置密码
  PASSWORD_CHANGE = 'password_change', // 修改密码
  EMAIL_REBIND = 'email_rebind', // 换绑 - 旧邮箱
  EMAIL_REBIND_NEW = 'email_rebind_new', // 换绑 - 旧邮箱
  EMAIL_UNBIND = 'email_unbind', // 解绑
}

export enum COMMON_VERIFY_CODE_SCENE {
  TRANSFER_CREATOR = 'transfer_creator',
}

export const DEBOUNCE_DEFAULT_SECOND = 300;

export const THROTTLE_DEFAULT_SECOND = 1000;
// 链接跳转时，重复会被浏览器cancel，需要节流
export const THROTTLE_REDIRECT_SECOND = 3000;

// 支持企业级登录页外观自定义-登录框位置
export enum LOGIN_POSITIONS {
  DEFAULT = 'DEFAULT',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

// 支持企业级登录页外观自定义-登录框位置
export const POSITIONS = {
  [LOGIN_POSITIONS.LEFT]: 'left',
  [LOGIN_POSITIONS.CENTER]: 'center',
  [LOGIN_POSITIONS.RIGHT]: 'right',
};

// 支持企业级登录页外观自定义-背景类型
export enum BACKGROUND_TYPES {
  IMAGE = 'IMAGE',
  COLOR = 'COLOR',
}

// 支持企业级登录页外观自定义-背景填充类型
export enum FILLING_TYPES {
  COVER = 'COVER', // 自适应屏幕 background-size: cover
  CONTAIN = 'CONTAIN', // 任意边充满屏幕 background-size: contain
}

// 企业登录页外观自定义模版类型：SaaS、meeting、doc
export enum TEMPLATES {
  SAAS = 'SaaS', // 常规登录页
  MEETING = 'meeting', // 腾会登录页
  DOC = 'doc', // 文档登录页
}

/* 密码策略相关常量 */

// 字符中必须包含
export enum INCLUDE {
  MIN_LOWER_CASE = 'minLowerCase', // 小写字母
  MIN_UPPER_CASE = 'minUpperCase', // 大写字母
  MIN_NUMBER = 'minNumber', // 数字字符0-9
  MIN_SYMBOL = 'minSymbol', // 特殊字符（例如*_.$!等）
}

// 字符中不可包含
export enum EXCLUDE {
  USERNAME = 'username', // 登录用户名
  PHONE_NUM = 'phoneNum', // 手机号码
  EMAIL_PREFIX = 'emailPrefix', // 邮箱前缀
  CARD_NUM = 'cardNum', // 工号
}

// 密码过期时间
export enum EXPIRATION_TIME {
  UNLIMITED = '0', // 不限
  FIFTEEN = '15', // 15天后过期
  THIRTY = '30', // 30天后过期
  FIFTY_FIVE = '45', // 45天后过期
  SIXTY = '60', // 60天后过期
  NINETY = '90', // 90天后过期
}

// 密码重复规则
export enum REPEAT_RULE {
  UNLIMITED = '0', // 不限
  ONE = '1', // 不可与前1个密码重复
  TWO = '2', // 不可与前2个密码重复
  THREE = '3', // 不可与前3个密码重复
}

// 密码长度
export enum PWD_LENGTH {
  MIN_LENGTH = 'minLength', // 密码最小长度
  MAX_LENGTH = 'maxLength', // 密码最大长度
}

export enum VERIFY_CODE_SCENE {
  LOGIN = 'login',
  FORGET_PASSWORD = 'forget_password',
  SOCIAL_LOGIN = 'social_login',
  INVITATION = 'invitation',
  BIND_MOBILE = 'bind_mobile', // 微信绑定手机号
  COMPLETE_MOBILE = 'complete_mobile', // 补全账户缺失的手机号
  EMAIL_HANDSHAKE = 'email_handshake', // 邮箱接受邀请
  NECESSARY_MOBILE = 'necessary_mobile', // 个人账号绑定手机号
}

const SAAS_TEMPLATE = {
  position: LOGIN_POSITIONS.DEFAULT,
  backgroundType: BACKGROUND_TYPES.COLOR,
  imageUrl: '',
  fillingType: FILLING_TYPES.COVER,
  color: publicStyles.tdColorInfo,
  customCssEnabled: false,
  css: '',
  template: TEMPLATES.SAAS,
  id: '',
};

const DOC_TEMPLATE = {
  position: LOGIN_POSITIONS.DEFAULT,
  backgroundType: BACKGROUND_TYPES.IMAGE,
  imageUrl: JSRuntime.getStaticPath('assets/docsLoginBg.png'),
  fillingType: FILLING_TYPES.COVER,
  color: publicStyles.tdColorWhite,
  customCssEnabled: false,
  css: '',
  template: TEMPLATES.DOC,
  id: '',
};

export const TEMPLATE_MAP = {
  [TEMPLATES.SAAS]: SAAS_TEMPLATE,
  [TEMPLATES.DOC]: DOC_TEMPLATE,
};

// 问题反馈链接
export const FEEDBACK_URL = JSRuntime.isProductSite
  ? 'https://wj.qq.com/s2/11038415/73a1/'
  : 'https://wj.qq.com/s2/11042486/6326/';

// 新上传图片流程中获取token和获取临时链接需要的upload_resource，全局唯一
export const UPLOAD_IMG_KEY = new Set([
  'avatar',
  'job_statements',
  'other_materials',
  'external_account_ownership_statements',
  'profession_qualifications',
  'additional_materials',
  'get_eid_corpmgr_corp_logo', // 企业logo
  'get_eid_corpmgr_auth_logo', // 认证源logo
  'get_eid_corpmgr_data_logo', // 数据源logo
  'get_eid_corpmgr_system_background', // 自定义背景
  'account_certify',
]);

export const MAINLAND_PHONE_REGEXP = /^\+86\s/; // 匹配以'+86 '开头的手机号

// 图片审核结果
export enum IMG_SAFE_CHECK_STATUS {
  BEFORE_CHECK = 0, // 未送审
  CHECK = 1, // 审核中
  SUCCESS = 2, // 审核成功
  FAIL = 3, // 审核失败
}

export enum IMG_SAFE_CHECK_TYPE {
  PERSON = 0,
  IMG_AUDITING = 1,
}

export enum REPORT_ERROR_NAME {
  CAPTCHA_ERROR = 'CAPTCHA_ERROR',
}

/** 超级管理员角色id */
export enum SUPER_ADMIN_ROLE_ID {
  /** 系统预置超管id */
  SYSTEM = '0',
  /** oneid超管id */
  ONEID = '1',
  /** 腾讯会议超管id */
  MEETING = '100000',
  /** 腾讯文档超管id */
  DOCS = '200000',
}
