import { getBlockedErrMsg } from 'common/components/BlockedErrMsg';

export enum EMAIL_VERIFY_CODE_SCENE {
  EMAIL_LOGIN = 'email_login',
  PASSWORD_FORGOT = 'password_forgot',
}

export enum ENTERPRISE_AUTH_TYPE {
  ENTERPRISE_AUTH = 'enterprise',
  ORG_AUTH = 'organization',
}
// 这是ENTERPRISE_AUTH_TYPE.ENTERPRISE_AUTH的子类型
export enum ENTERPRISE_CERT_TYPES {
  ELECTRICAL_BUSINESS_LICENSE = 1,
  ENTERPRISE_AUTHORIZATION_LETTER = 2,
  TENCENT_CLOUD = 4,
}
// 这是ENTERPRISE_AUTH_TYPE.ORG_AUTH的子类型
export enum ORG_CERT_TYPES {
  ORG_AUTHORIZATION_LETTER = 3,
}

export enum CREATE_CARD_TYPE {
  CREATE_PERSONAL_ACCOUNT,
  CREATE_ORG_ACCOUNT,
  LOGIN_ACCOUNT,
}

// 账号被封禁时错误码
export const BLOCKED_ERROR_CODE = 'E0010344';

export const BLOCKED_ERROR_MSG = {
  [BLOCKED_ERROR_CODE]: getBlockedErrMsg(),
};

// export const MAINLAND_PHONE_REGEXP = /^\+86\s/; // 匹配以'+86 '开头的手机号

export enum PASSWORD_LOGIN_TYPE {
  EMAIL = 'email',
  MOBILE = 'mobile',
}

/** 认证类型，区分企业认证个人认证，与接入应用的传参进行对应 */
export enum AUTH_TYPE {
  /** 是否认证过 */
  UNKNOWN = '0',
  /** 达人 */
  KOL = '1',
  /** 职业 */
  OCCUPATION = '2',
  /** 企业 */
  ENTPRISE = '3',
  /** 组织 */
  ORGANIZATION = '4',
}
/** 企业认证的具体认证类型 */
export enum ENT_AUTH_TYPE {
  /** 未知认证方式 */
  UNKNOWN = 0,
  /** 电子营业执照 */
  LICENSE = 1,
  /** 企业授权函 */
  ENTERPRISE = 2,
  /** 组织机构授权函 */
  ORGANIZATION = 3,
  /** 腾讯云认证 */
  TENCENT = 4,
}

/** 保存类型，企业认证中使用 */
export enum SAVE_TYPE {
  /** 草稿 */
  DRAFT = 1,
  /** 提交审核 */
  APPLY = 2,
}

export const RESET_PWD_ERROR_CODE = ['E0010381', 'E0010382', 'E0010383', 'E0010384'];
