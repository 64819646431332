import { AuthnApi as CommonAuthApi } from 'common/api';
import { addQueryToRequest } from 'common/compositions/WeMeetQrCode/hooks/utils';
import Utils from 'common/utils';
import JSRuntime from 'common/utils/JSRuntime';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { MEETING_LOGIN_ERR_LIST, SSO_LOGIN_METHOD } from './constants/Constants';

class SSOLoginStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<SSOLoginStore>;
  public resetStore: () => void;

  public ssoLoginMethod = SSO_LOGIN_METHOD.DOMAIN;
  public isNextLoading = false;
  public loginErrMsg = '';

  public jumpToSsoDomain(sso: string) {
    const configUrl = new URL(JSRuntime.originalIdpSite);
    const { pathname, protocol, search, hash } = window.location;
    Utils.safeRedirect(`${protocol}//${sso}.${configUrl.host}${pathname}${search}${hash}/identity`);
  }

  public jumpToIdaas(redirectUrl: string) {
    Utils.safeRedirect(redirectUrl);
  }

  public meetingLoginBySSO(data: { domain: string; last_authorize_url: string; type: number }) {
    CommonAuthApi.loginBySSO(data, addQueryToRequest())
      .then((res) => {
        const type = m.get(res, 'data.sso_type');
        const redirectUrl = m.get(res, 'data.redirect_url');
        const code = m.get(res, 'code');
        const message = m.get(res, 'message');

        if (code !== 0) {
          if (MEETING_LOGIN_ERR_LIST.includes(_.toString(code))) {
            this.updateStore({
              loginErrMsg: message,
            });
          } else {
            return Promise.reject(message);
          }
        }

        if (type === 'idaas' && redirectUrl) {
          this.jumpToIdaas(redirectUrl);
        }

        return res;
      })
      .catch((error: string) => {
        this.global.handleError(error);
      })
      .finally(() => {
        this.updateStore({
          isNextLoading: false,
        });
      });
  }
}

export default SSOLoginStore;
