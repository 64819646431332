import { AccountsApi, AuthnApi } from 'common/api';
import { redirectIOA } from 'common/compositions/RedirectIOA';
import { LOGIN_ERROR_MSG } from 'common/compositions/VerifyDialog/Constants';
import { ICodeReq, IPasswordReq } from 'common/compositions/VerifyDialog/Types';
import { ACCOUNT_TYPE, VERIFY_CODE_SCENE } from 'common/constants/Constants';
import { handleError } from 'common/utils/ErrorHandler';
import JSRuntime from 'common/utils/JSRuntime';
import { AuthnApi as IdpAuthnApi } from 'idpBase/api';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { ENTERPRISE_LOGIN_TYPE, WECHAT_VERIFY_ERROR } from './constants/Constants';
import { IAccount, IVerifyWeChat } from './constants/Types';

class SelectAccountStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<SelectAccountStore>;
  public resetStore: () => void;

  public showMobileVerifyDialog = false;
  public showWeChatVerifyDialog = false;
  public showBindMobileDialog = false;
  public showNaturalVerifyDialog = false;
  public isAccountLoading = true;
  public isAmrSSO = false;
  public activeAccountId = '';
  public selectedAccountId = '';
  public accounts: IAccount[] = [];
  public unavailable: IAccount[] = [];
  public originAccounts: IAccount[] = [];

  get personVerify() {
    return this.global.rootStore.userInfo?.user?.personVerify || [];
  }

  get fuzzyMobile() {
    return this.global.rootStore.userInfo?.user?.fuzzyMobile || '';
  }

  get weChat() {
    return this.global.rootStore.userInfo?.user?.weChat || '';
  }

  public init() {
    this.updateStore({
      selectedAccountId: this.global.rootStore.account?.accountId,
    });
    return Promise.all([this.getAccounts(), this.global.rootStore.getJoinRoles()]);
  }

  private getAccounts() {
    const search = _.get(window.location, 'search');
    const searchParams = JSRuntime.parseLocationSearch(search);
    const amr = m.get(searchParams, 'amr');
    if (amr === ENTERPRISE_LOGIN_TYPE) {
      const {
        account,
        userInfo: { accountUser },
      } = this.global.rootStore || {};
      // 补充accounts的数据结构，统一展示样式与后续数据获取
      this.updateStore({
        originAccounts:
          account && accountUser
            ? [
                {
                  id: account.accountId,
                  name: account.name,
                  userName: accountUser.name,
                  accountType: account.accountType,
                  units: accountUser.units,
                  logoUrl: accountUser.avatarUrl,
                  isCreator: accountUser.isCreator,
                  domain: '',
                  avatar: accountUser.avatarUrl,
                  available: true,
                },
              ]
            : [],
        isAccountLoading: false,
        isAmrSSO: true,
      });
      return;
    }
    return AccountsApi.getAccounts(JSRuntime.backEndApp)
      .then((res) => {
        // Tips: 有开通能力的放在前面
        const availableAccounts: IAccount[] = [];
        let unavailableAccounts: IAccount[] = [];
        if (_.isArray(res)) {
          res?.forEach((a: IAccount) => {
            if (a.available) availableAccounts.push(a);
            else if (a.noAccess) unavailableAccounts.push(a);
            else unavailableAccounts.unshift(a);
          });
        }

        if (JSRuntime.isTencentMeeting) {
          unavailableAccounts =
            unavailableAccounts?.filter((item) => item.accountType !== ACCOUNT_TYPE.COMPANY) || [];
        }
        if (
          [JSRuntime.front2BackApp.docs, JSRuntime.front2BackApp.sogouinput].includes(
            JSRuntime.appFromUrl,
          )
        ) {
          unavailableAccounts =
            unavailableAccounts?.filter((item) => item.accountType !== ACCOUNT_TYPE.PERSONAL) || [];
        }

        this.updateStore({
          accounts: availableAccounts,
          unavailable: unavailableAccounts,
          originAccounts: [...availableAccounts, ...unavailableAccounts],
        });
      })
      .catch((err) => {
        handleError(err);
      })
      .finally(() => {
        this.updateStore({
          isAccountLoading: false,
        });
      });
  }

  public changeAccount(params: { id?: string; isEmail?: boolean; unionId?: string }) {
    const apiPromise = params?.isEmail
      ? AuthnApi.changeEmailAccount(params?.id || this.selectedAccountId, params?.unionId || '')
      : AuthnApi.changeAccount(
          params?.id || this.selectedAccountId,
          params?.unionId || '',
          JSRuntime.backEndApp,
        );
    return apiPromise
      .then((res = {}) => {
        const { verifyMobile, verifyWechat, lackMobile } = res;
        if (verifyMobile) {
          this.updateStore({
            showMobileVerifyDialog: true,
          });
        } else if (verifyWechat) {
          this.updateStore({
            showWeChatVerifyDialog: true,
          });
        } else if (lackMobile) {
          this.updateStore({
            showBindMobileDialog: true,
          });
          // 如果不需要ioa验证，直接跳转
        } else if (!redirectIOA(res, JSRuntime.getPortalSite())) {
          this.global.rootStore.redirectToLandingPage();
        }
      })
      .catch((err: Error) => {
        this.updateStore({ activeAccountId: '' });
        const errMessage = LOGIN_ERROR_MSG[_.get(err, 'data.errCode')];
        handleError(errMessage || err);
        return Promise.reject();
      });
  }
  public verifyWeChat(params: IVerifyWeChat) {
    return IdpAuthnApi.verifyWeChatAndSwitch({
      accountId: this.selectedAccountId,
      ...params,
    })
      .then((res) => (redirectIOA(res, JSRuntime.getPortalSite()) ? Promise.reject(true) : res))
      .catch((err) => {
        if (err === true) return Promise.reject();
        const errCode = m.get(err, 'data.errCode');
        if (WECHAT_VERIFY_ERROR[errCode]) {
          return Promise.reject(WECHAT_VERIFY_ERROR[errCode]);
        }
        this.global.handleError(err);
        return Promise.reject();
      });
  }

  public getCompleteSMS() {
    return AuthnApi.getSMS('select_account_complete');
  }

  public verifyCompleteCode(params: ICodeReq) {
    return AuthnApi.verifyCode({
      ...params,
      accountId: this.selectedAccountId,
      event: 'select_account_complete',
    });
  }

  public getSMS() {
    return AuthnApi.getSMS();
  }

  // 绑定手机号获取验证码
  public getBindMobileSMS(mobile: string) {
    return AuthnApi.sendVerificationCode({ mobile, event: VERIFY_CODE_SCENE.NECESSARY_MOBILE });
  }

  public verifyBindMobileCode(params: ICodeReq) {
    return AuthnApi.verifyCode({
      ...params,
      event: VERIFY_CODE_SCENE.NECESSARY_MOBILE,
      accountId: this.selectedAccountId,
    });
  }

  public verifyCode(params: ICodeReq) {
    return AuthnApi.verifyCode({ ...params, accountId: this.selectedAccountId });
  }

  public verifyPassword(params: IPasswordReq) {
    return AuthnApi.verifyPassword({ ...params, accountId: this.selectedAccountId });
  }

  // 开通个人版会议，获取权限
  public getAppAuth(redirectUrl?: string) {
    return IdpAuthnApi.getAppAuth({
      app: JSRuntime.backEndApp,
      redirectUrl,
    });
  }
}

export type TSelectAccountStore = SelectAccountStore;

export default SelectAccountStore;
