import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator';
import APINames from '../APINames';

class AccountsApi extends BaseApi {
  public requestInstance = new Request(APINames.ACCOUNTS);

  // 获取可切换租户列表
  getAccounts(app?: string) {
    return this.requestInstance.get('', { app });
  }
}
export default new AccountsApi();
