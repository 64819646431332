/*
 * Api 集中处理
 *
 * 1. 配置文件解析
 * 2. api 生成
 * */

import { IResponseInterceptors, TRequestConfig, TResponse } from './Request';
import DecoratorRequest from './Request/DecoratorRequest';

export type RequestConfig = TRequestConfig;

export type ReqResponse = TResponse;

// 为了适应不同模块的 BaseURL 只再其模块下有效，这里提供创建 Api 的 函数
const ModuleApiCreator = (
  moduleBaseURL: string,
  getResponseInterceptors?: () => IResponseInterceptors,
) => {
  const decoratorConfig = {
    baseURL: moduleBaseURL,
    getResponseInterceptors,
  };
  return {
    Request: DecoratorRequest(decoratorConfig),
  };
};

export default ModuleApiCreator;
