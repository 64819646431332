import BaseApi from 'common/apiRequest/apis/BaseApi';
import { VERIFY_CODE_SCENE } from 'common/constants/Constants';
import captchaRequest from 'common/utils/captchaRequest';
import JSRuntime from 'common/utils/JSRuntime';
import { Request } from '../apiConcentrator';
import APINames from '../APINames';

class AuthnApi extends BaseApi {
  public requestInstance = new Request(APINames.AUTHN, {
    baseURL: `${JSRuntime.apiSite}/v1`,
  });

  public requestInstance2 = new Request(APINames.AUTHN, {
    baseURL: `${JSRuntime.accountApiSite}/v1`,
  });

  public weMeetRequestInstance = new Request('', {
    baseURL: `${JSRuntime.accountApiSite}`,
  });

  selectOrg(id: string) {
    return this.requestInstance.post('account', { id });
  }

  logout(redirectURL?: string) {
    return new Promise((res, rej) => {
      try {
        res(
          window.open(
            `${JSRuntime.apiSite}/v1/authn:logout${
              redirectURL ? `?redirect_uri=${encodeURIComponent(redirectURL)}` : ''
            }`,
            '_self',
          ),
        );
      } catch (e) {
        rej(e);
      }
    });
  }

  getOrgs() {
    return this.requestInstance.get(':list_accounts');
  }

  // 删除个人版账户
  delPersonalAccount(id: string) {
    return this.requestInstance.del(['accounts', id]);
  }

  // 获取社会认证源信息
  getSocialIdp(idpType: string) {
    return this.requestInstance.get(['sso', 'socialIdp', idpType]);
  }

  // 获取企微信息
  getWeComInfo(idpType: string) {
    return this.requestInstance.get(['sso', 'ssoIdp', `${idpType}`]);
  }

  // 认证源登录, 获取state值
  getSSOLoginState(accountId: string, idpInstanceId: string) {
    return this.requestInstance.get(['sso', accountId, 'idp', idpInstanceId, 'applyLogin']);
  }
  // 获取SSO登录链接
  getLoginSSOLink(appId: string) {
    const baseURL = _.get(this.requestInstance, 'baseURL');

    return `${baseURL}/${APINames.AUTHN}/sso/${appId}`;
  }

  // 自然人二次验证获取验证码
  getSMS(event?: string) {
    return captchaRequest((captchaVerification) =>
      this.requestInstance2.post(['factors', 'sms:send'], {
        event: event || 'switch_account',
        captchaVerification,
      }),
    );
  }

  // 绑定手机号用验证码
  getBindMobileSMS(mobile: string) {
    return this.requestInstance2.post(['personal', 'sms:send'], {
      mobile,
    });
  }

  // 自然人二次验证检验验证码
  verifyCode(param: {
    verificationCode: string;
    verificationSessionId: string;
    accountId: string;
    event?: string;
  }) {
    return this.requestInstance2.post(['factors', 'sms:verify'], {
      event: 'switch_account',
      ...param,
    });
  }

  verifyPassword(param: {
    password: string;
    captchaVerification?: Record<string, any>;
    accountId: string;
  }) {
    return this.requestInstance2.post(['factors', 'password'], { ...param });
  }

  changeEmailAccount(id: string, unionId: string) {
    return this.requestInstance2.post(['emails', `${id}:switch`], { unionId });
  }

  changeAccount(id: string, unionId: string, app?: string) {
    return captchaRequest((captchaVerification) =>
      this.requestInstance2.post(['accounts', `${id}:switch`], {
        id,
        unionId,
        captchaVerification,
        app,
      }),
    );
  }

  // 获取登录设备列表
  getLoginDevices() {
    return this.requestInstance.get(['devices']);
  }

  // 退出设备登录
  logoutDevice(deviceId: string) {
    return this.requestInstance.post(['device', deviceId, ':logout']);
  }

  // 代理的会议侧的 session_key 获得接口
  getQRCodeSessionKey(body: { uuid: string; instance_id: number }, params: Record<string, any>) {
    return this.weMeetRequestInstance.post(
      '/wemeet-tapi/v2/user-logic/login/qr-code/session-key',
      { ...body, business_type: 'EID' },
      params,
    );
  }

  // 代理的会议侧的 轮询状态接口
  queryQRCodeStatus(body: { session_key: string }, params: Record<string, any>) {
    return this.weMeetRequestInstance.post(
      '/wemeet-tapi/v2/user-logic/login/qr-code/query-status',
      { ...body, business_type: 'EID' },
      params,
    );
  }

  // 代理的会议侧的 sso 登录接口
  loginBySSO(
    body: { domain: string; last_authorize_url: string; type: number },
    params: Record<string, any>,
  ) {
    return this.weMeetRequestInstance.post('/wemeet-tapi/v2/user-logic/sso/login', body, params);
  }

  sendVerificationCode(params: { mobile: string; event: VERIFY_CODE_SCENE }) {
    return captchaRequest((captchaVerification) =>
      this.requestInstance2.post(['credentials', 'sms:send'], {
        ...params,
        captchaVerification,
      }),
    );
  }

  // 获取密码加密密钥
  getPwdEncryptKey() {
    return this.requestInstance.get(['encrypt_setting']);
  }

  // 获取应用列表
  getApps() {
    return this.requestInstance.get(['apps']);
  }
}
export default new AuthnApi();
