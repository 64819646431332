import { AxiosInstancePlus } from '@tencent/oneid-request';
import type { IResponseType } from 'common/constants/Types';
import { http } from 'common/http';

class RolesApi {
  public axiosInstance: AxiosInstancePlus = http;

  /** 获取用户加入的角色列表 */
  async getJoinRoles() {
    const res: IResponseType = await this.axiosInstance.get('/admin/v3/role/join', {
      skipShowError: true,
    });
    if (!res || res.errCode) return [];

    return res as { role_id: string }[];
  }
}

export default new RolesApi();
