import BaseApi from 'common/apiRequest/apis/BaseApi';
import { Request } from '../apiConcentrator/AdminV3';

class RolesApi extends BaseApi {
  public requestInstance = new Request();

  /**
   * 获取所有的权限点
   */
  getActionGroups(code = 'OneID') {
    return this.requestInstance.get(['action_groups'], { code });
  }
}

export default new RolesApi();
