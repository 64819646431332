import { AxiosResponse } from '@tencent/oneid-request';
import { TApiError } from 'common/apiRequest/Request';
import message from 'common/components/Message';
import { NO_COMPANY_ERROR, UNKNOWN_MISTAKE } from 'common/constants/Constants';
import Utils from 'common/utils';

type TError = { errCode: string; errMessage: string; [key: string]: any };

/**
 * @param response 请求成功拦截器
 * @returns response.data
 */
const onSuccess = (response: AxiosResponse): Promise<any> => {
  const data = response.data || {};
  // 处理业务报错，状态为200，无需上报
  if (data.errCode) {
    const err = { ...response, ...data };
    return handleBusinessArr(err);
  }

  // if (JSRuntime.isProductSite) {
  //   window.aegis?.info(`Response: ${Utils.aegisSuccessInfo(response)}`);
  // }

  return Promise.resolve(data);
};

/**
 * @param error 请求失败，状态码非200
 * @returns Promise.resolve(err)
 */
const onError = (error: TApiError) => {
  // status = 0 断网
  // const err = error.response || { status: 0 };

  // 降噪，注释掉，使用伽利略的自动捕获接口异常能力
  // if (JSRuntime.isProductSite && err?.status >= 500) {
  //   window.aegis?.error(Utils.aegisErrorInfo(error));
  // }

  return handleError(error);
};

const handleError = (error: TApiError) => {
  const errData = _.get(error.response || {}, 'data') as TError;
  // 网关报错时没有 errCode 字段
  const err = errData?.errCode
    ? { ...error, ...errData }
    : {
        ...error,
        status: Number(error.status || 0),
        errCode: error.code || '',
        errMessage: error.message || UNKNOWN_MISTAKE,
      };
  const status = _.get(err, 'status');

  // 重复请求自动取消，无需处理
  if (_.get(error, 'code') === 'ERR_CANCELED') {
    return Promise.resolve(err);
  }

  // 处理401，403，412特殊逻辑
  switch (status) {
    case 401: {
      Utils.handleNoAuth();
      return Promise.resolve(err);
    }
    case 403: {
      handleForbidden(err);
      return Promise.resolve(err);
    }
    case 412: {
      handleNoCompany(NO_COMPANY_ERROR);
      return Promise.resolve(err);
    }
    default: {
      return handleBusinessArr(err);
    }
  }
};

// 412 自定义域名下，域名不可用则跳转到当前自定义域名的 IDP 错误页
const handleNoCompany = (errCode: string) => {
  Utils.redirectToIdp(undefined, `/sso_login/error?errCode=${errCode}`);
};

// 403 admin模块 二次验证过期，其他模块不处理
const handleForbidden = (error: TError) => {
  if (RUNTIME_APP_NAME === 'admin' && _.get(error, 'errCode') === 'E0010394') {
    window.location.reload();
  }
};

// 处理业务报错，状态为200
const handleBusinessArr = (error: TError) => {
  // 跳过默认右下角弹窗报错行为
  if (_.get(error, 'config.skipShowError')) {
    return Promise.resolve(error);
  }

  const errorMsg = _.get(error, 'errMessage');

  if (errorMsg) {
    message.error({ content: errorMsg });
  }
  return Promise.resolve(error);
};

export { onSuccess, onError };
