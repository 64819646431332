import { AccountApi, AccountsApi, AuthnApi, UserApi, UserPersonalAccountApi } from 'common/api';
import { LOGIN_ERROR_MSG } from 'common/compositions/VerifyDialog/Constants';
import { ACCOUNT_TYPE, USER_VERIFY_CODE_SCENE } from 'common/constants/Constants';
import { IAccount } from 'common/constants/Types';
import { AdminUserApiV2, UserAccountsApi } from 'idpBase/api';
import { BLOCKED_ERROR_CODE, BLOCKED_ERROR_MSG } from 'idpBase/Constants';
import { TGlobalStore, TUpdateStore } from 'idpBase/store';
import { IDevice, ITenant } from './constatns/Types';
import { TENANT_ERROR_MSG } from '../constants/Constants';

class AccountManageStore {
  public readonly global: TGlobalStore;
  public updateStore: TUpdateStore<AccountManageStore>;
  public resetStore: () => void;

  public curDeviceId = '';
  public devices: IDevice[] = [];
  public isBlockLoading = true;
  public tenantDialogVisible = false;
  public selectedTenant = {} as ITenant; // 当前选择操作的租户

  public writeOffCount = 10;
  public writeOffTimer = null as unknown as NodeJS.Timeout;
  public writeOffVisible = false;
  public isWriteOffLoading = false;

  public tenantChangeBindVisible = false;

  public tenants: ITenant[] = [];
  public annotationOverrides = {
    writeOffTimer: false,
  };

  public get personalTenant() {
    return this.tenants?.find((tenant) => m.get(tenant, 'accountType') === ACCOUNT_TYPE.PERSONAL);
  }

  public get companyTenants() {
    return m.isArray(this.tenants)
      ? this.tenants.filter((tenant) => m.get(tenant, 'accountType') === ACCOUNT_TYPE.COMPANY)
      : [];
  }

  public account = {} as IAccount;

  public init() {
    return Promise.all([
      this.global.profileStore.getWeComBindInfo(),
      // 目前前端登录设备管理模块被隐藏，该接口有时会报错，所以暂时屏蔽该接口调用
      // this.getLoginDevices(),
      this.getTenants(),
      this.getDomainConfig(),
    ]).finally(() => {
      this.updateStore({ isBlockLoading: false });
    });
  }

  // 获取登录设备列表
  public getLoginDevices() {
    return AuthnApi.getLoginDevices()
      .then((res) => {
        const curDeviceId = m.get(res, 'curDeviceId');
        const devices = m.get(res, 'devices') || [];
        // 把当前设备放到第一位
        const curDevice = _.remove(devices, (d) => m.get(d, 'deviceId') === curDeviceId) || [];
        devices.unshift(...curDevice);
        this.updateStore({ curDeviceId, devices });
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }

  // 退出设备登录
  public logoutDevice(deviceId: string) {
    return AuthnApi.logoutDevice(deviceId)
      .then(() => {
        this.global.handleSuccess('设备已删除');
        this.getLoginDevices();
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }

  // 获取当前关联的租户列表
  public getTenants() {
    return AccountsApi.getAccounts()
      .then((tenants) => {
        if (m.isArray(tenants)) this.updateStore({ tenants });
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }

  // 发送验证码
  public sendCode(event?: USER_VERIFY_CODE_SCENE, mobile?: string) {
    return UserApi.sendCode(event, mobile);
  }

  public rebindTenantSendCode(accountId: string, mobile: string) {
    return UserApi.rebindTenantSendCode(accountId, { mobile });
  }

  // 验证验证码
  public verifyCode(
    verificationCode: string,
    verificationSessionId: string,
    event?: USER_VERIFY_CODE_SCENE,
  ) {
    return UserApi.verifyMobile({
      verificationCode,
      verificationSessionId,
      event,
    });
  }

  public resetMobile() {
    return UserApi.resetMobile();
  }

  public writeOffCountDown() {
    clearTimeout(this.writeOffTimer);
    if (this.writeOffCount <= 0) return;
    this.writeOffTimer = setTimeout(() => {
      this.updateStore({ writeOffCount: this.writeOffCount - 1 });
      this.writeOffCountDown();
    }, 1000);
  }

  // 企业版注销
  public companyWriteOff(accountId: string) {
    this.updateStore({ writeOffCount: 10, isWriteOffLoading: true });
    return AdminUserApiV2.writeOffTenant(accountId)
      .then(() => {
        this.global.handleSuccess('已退出');
      })
      .catch((err) => {
        if (m.get(err, 'data.errCode') === BLOCKED_ERROR_CODE) {
          this.global.handleError(BLOCKED_ERROR_MSG[BLOCKED_ERROR_CODE]);
        } else {
          this.global.handleError(TENANT_ERROR_MSG);
        }
        return Promise.reject(err);
      })
      .finally(() => {
        this.updateStore({ isWriteOffLoading: false });
      });
  }

  // 个人版注销
  public personalWriteOff() {
    const name = m.get(this.selectedTenant, 'name');
    this.updateStore({ writeOffCount: 10, isWriteOffLoading: true });
    return UserPersonalAccountApi.delPersonalAccount({ companyName: name })
      .then(() => {
        this.global.handleSuccess('已注销');
      })
      .catch((error: Error) => {
        this.global.handleError(TENANT_ERROR_MSG);
        return Promise.reject(error);
      })
      .finally(() => {
        this.updateStore({ isWriteOffLoading: false });
      });
  }

  // 租户换绑至其他手机号
  public tenantRebind(data: {
    verificationCode: string;
    verificationSessionId: string;
    mobile: string;
  }) {
    const id = m.get(this.selectedTenant, 'id');
    return UserAccountsApi.rebindMobileOfTenant(id, data);
  }

  public changeAccount(id: string, unionId: string) {
    return AuthnApi.changeAccount(id, unionId).catch((err) => {
      const errMessage = LOGIN_ERROR_MSG[_.get(err, 'data.errCode')];
      this.global.handleError(errMessage || err);
      return Promise.reject();
    });
  }

  public getDomainConfig() {
    return AccountApi.getDomainConfig()
      .then((account) => {
        this.updateStore({ account: account || {} });
      })
      .catch((err) => {
        this.global.handleError(err);
      });
  }
}

export default AccountManageStore;
