import Utils from 'common/utils';
import { mergeBaseReport } from './mergeBaseReport';
import { getGlobalReportParams } from './reportParams/getGlobalReportParams';
import { getOptionalReportParams } from './reportParams/getOptionalReportParams';
import { doBeaconReport } from './reportParams/utils';

// NOTICE 根据我们实际业务情况，全局公参中，有部分数据没办法通过function直接获取，该部分数据跟业务比较相关，所以选择传参形式
const wemeetBeaconReport = (eventName: string, params: any = {}, extra?: any) => {
  try {
    if (typeof window !== 'undefined') {
      let reportParams: any = {};
      // 用户参数
      const userMergedParams = mergeBaseReport(params, extra);
      // 全局公参
      const globalReportParams = getGlobalReportParams(eventName, params);
      // 可选全局参数
      const optionalReportParams = getOptionalReportParams(params);

      // NOTICE 已经跟产品确认过，我们只有新事件上报
      // 新事件上报，优先级：全局公参 > 可选全局公参 > 业务参数
      reportParams = {
        ...userMergedParams,
        ...optionalReportParams,
        ...globalReportParams,
      };
      doBeaconReport(eventName, reportParams);
    }
  } catch (err) {
    Utils.noop();
  }
};

export default wemeetBeaconReport;
